@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@300;400;500;600;700;900&family=Noto+Sans+KR:wght@100..900&display=swap');

body {
    font-family: "Noto Sans KR", sans-serif;
}

.react-datepicker__close-icon {
  padding-right: 0.5rem !important;
  padding-top: 0.25rem !important;
}

.react-datepicker__close-icon::after {
  background-color: #D1D5DB !important;
  padding: 1px !important;
  font-size: 9px !important;
}
